import { createStore } from "vuex"

const serviceApiUrl = process.env.VUE_APP_API_SERVICE_URL
const goodsApiUrl = process.env.VUE_APP_API_GOODS_URL

export default createStore({
  state: {
    params: {
      lang: "en",
      withLangs: false,
      domain: "https://",
      apiServiceUrl: serviceApiUrl,
      apiGoodsUrl: goodsApiUrl,
    },
    userSessionStarted: false,
    userRole: "",
    accessToken: "",
    refreshToken: "",
    userRolePermissions: {
      root: {
        modules: true,
        about: true,
      },
      admin: {
        modules: true,
        about: true,
      },
    },
    dict: {
      brands: [],
      categories: [],
    },
    modules: [
      {
        module_id: 1,
        module_name: "banners",
        module_title: "Баннеры",
        apiUrl: "news",
        title: "Баннеры",

        fields: [
          {
            name: "category_id",
            label: "category_id",
            type: "int",
            required: false,
            show_in_list: true,
            column_width: "20%",
            ordernum: 1,
          },
          {
            name: "priority",
            label: "priority",
            type: "int",
            required: true,
            show_in_list: true,
            column_width: "10%",
            ordernum: 2,
          },
          {
            name: "link",
            label: "link",
            type: "link_constructor",
            required: false,
            show_in_list: true,
            column_width: "20%",
            ordernum: 3,
          },
          {
            name: "image",
            label: "image",
            type: "image",
            show_in_list: true,
            column_width: "20%",
            ordernum: 4,
          },
          {
            name: "video",
            label: "video",
            type: "file",
            show_in_list: true,
            column_width: "20%",
            ordernum: 5,
          },
        ],
      },
      {
        module_id: 2,
        module_name: "stories",
        module_title: "Блок сторис",
        apiUrl: "story_block",
        title: "Блок сторис",

        fields: [
          {
            name: "name",
            label: "name",
            type: "string",
            required: true,
            show_in_list: true,
            column_width: "30%",
            ordernum: 1,
          },
          {
            name: "priority",
            label: "priority",
            type: "int",
            required: true,
            show_in_list: true,
            column_width: "10%",
            ordernum: 2,
          },
          {
            name: "is_hidden",
            label: "is_hidden",
            type: "bool",
            required: true,
            show_in_list: true,
            column_width: "10%",
            ordernum: 3,
          },
          {
            name: "image",
            label: "image",
            type: "image",
            required: true,
            show_in_list: true,
            column_width: "20%",
            ordernum: 4,
          },
        ],
      },
      {
        module_id: 3,
        module_name: "slides",
        module_title: "Слайд сторис",
        apiUrl: "story_slide",
        title: "Слайд сторис",

        fields: [
          {
            name: "name",
            label: "name",
            type: "string",
            required: true,
            show_in_list: true,
            column_width: "30%",
            ordernum: 1,
          },
          {
            name: "duration",
            label: "duration",
            type: "int",
            required: true,
            show_in_list: true,
            column_width: "10%",
            ordernum: 3,
          },
          {
            name: "block_id",
            label: "block_id",
            type: "link_to_item",
            required: true,
            show_in_list: true,
            column_width: "10%",
            ordernum: 5,
            linked_module_url: "story_block",
          },
          {
            name: "is_hidden",
            label: "is_hidden",
            type: "bool",
            required: true,
            show_in_list: true,
            column_width: "10%",
            ordernum: 4,
          },
          {
            name: "image",
            label: "image",
            type: "image",
            show_in_list: true,
            column_width: "20%",
            ordernum: 10,
          },
          {
            name: "button_text",
            label: "button_text",
            type: "string",
            show_in_list: false,
            ordernum: 7,
          },
          {
            name: "button_link",
            label: "button_link",
            type: "link_constructor",
            show_in_list: false,
            ordernum: 8,
          },
          {
            name: "description",
            label: "description",
            type: "text",
            show_in_list: false,
            ordernum: 2,
          },
          {
            name: "slide_type",
            label: "slide_type",
            type: "toggle",
            required: true,
            show_in_list: true,
            variants: [
              { title: "normal", val: "normal" },
              { title: "goods", val: "goods" },
              { title: "button", val: "button" },
            ],
            ordernum: 9,
          },
          {
            name: "video",
            label: "video",
            type: "file",
            show_in_list: false,
            ordernum: 12,
          },
          {
            name: "offer_ids",
            label: "offer_ids",
            type: "offer_ids",
            show_in_list: true,
            ordernum: 6,
          },
        ],
      },
      {
        module_id: 4,
        module_point: "goods",
        module_name: "collection",
        module_title: "Подборки",
        apiUrl: "collection",
        title: "Подборки",

        fields: [
          {
            name: "type",
            label: "type",
            type: "toggle",
            show_in_list: false,
            variants: [
              { title: "brand", val: "brand" },
              { title: "category", val: "category" },
            ],
            ordernum: 2,
          },
          {
            name: "name",
            label: "name",
            type: "string",
            show_in_list: true,
            column_width: "20%",
            ordernum: 1,
          },
          {
            name: "type_value",
            label: "type_value",
            type: "collection_type_value",
            show_in_list: true,
            column_width: "20%",
            ordernum: 3,
          },
          {
            name: "is_hidden",
            label: "is_hidden",
            type: "bool",
            show_in_list: true,
            column_width: "10%",
            ordernum: 4,
          },
          {
            name: "link",
            label: "link",
            type: "link_constructor",
            required: false,
            show_in_list: true,
            column_width: "20%",
            ordernum: 5,
          },
        ],
      },
      {
        module_id: 5,
        module_point: "goods",
        module_name: "collection_search",
        module_title: "Подборки для поиска",
        apiUrl: "collection_search",
        title: "Подборки для поиска",
        fields: [
          {
            name: "type",
            label: "type",
            type: "toggle",
            show_in_list: true,
            column_width: "20%",
            variants: [
              { title: "brand", val: "brand" },
              { title: "category", val: "category" },
            ],
            ordernum: 1,
          },
          {
            name: "name",
            label: "name",
            type: "string",
            show_in_list: true,
            column_width: "20%",
            ordernum: 2,
          },
          {
            name: "type_value",
            label: "type_value",
            type: "collection_type_value",
            show_in_list: true,
            column_width: "20%",
            ordernum: 3,
          },
          {
            name: "link",
            label: "link",
            type: "link_constructor",
            required: false,
            show_in_list: true,
            column_width: "20%",
            ordernum: 4,
          },
          {
            name: "is_hidden",
            label: "is_hidden",
            type: "bool",
            show_in_list: true,
            column_width: "20%",
            ordernum: 5,
          },
        ],
      },
    ],
    menuModules: [],
    pagesTransition: false,
    notifications: [],
  },
  getters: {
    getUserRole: (state) => () => {
      return state.userRole
    },
    getAccessToken: (state) => () => {
      return state.accessToken
    },
    getRefreshToken: (state) => () => {
      return state.refreshToken
    },
    getUserRolePermissions: (state) => () => {
      if (state.userRole != "") return state.userRolePermissions[state.userRole]
      else return {}
    },
    getParams: (state) => () => {
      return state.params
    },
    getDict: (state) => () => {
      return state.dict
    },
    getNotifications: (state) => () => {
      return state.notifications
    },
    getModules: (state) => () => {
      return state.modules
    },
    getModuleById: (state) => (id) => {
      return state.modules.find((item) => {
        return item.module_id == id
      })
    },
    getMenuModules: (state) => () => {
      return state.modules
    },
    getPagesTransition: (state) => () => {
      return state.pagesTransition
    },
    getBrands: (state) => () => {
      return state.dict.orderManagers
    },
    getCategories: (state) => () => {
      return state.dict.paymentTypes
    },
  },
  mutations: {
    SET_MODULES_LIST(state, data) {
      state.menuModules = data.menuItems
      state.modules = data.modules
    },
    SET_NOTIFICATIONS(state, data) {
      state.notifications = data
    },
    SET_BRANDS(state, data) {
      state.dict.brands = data
    },
    SET_CATEGORIES(state, data) {
      state.dict.categories = data
    },
    SET_PARAMS(state, p) {
      state.params = p
    },
    SET_USER_ROLE(state, p) {
      state.userRole = p
    },
    SET_ACCESS_TOKEN(state, token) {
      state.accessToken = token
    },
    SET_REFRESH_TOKEN(state, token) {
      state.refreshToken = token
    },
    SET_TRANSITION(state, val) {
      state.pagesTransition = val
    },
  },
  actions: {
    setModulesList({ commit }, newValue) {
      commit("SET_MODULES_LIST", newValue)
    },
    setNotifications({ commit }, newValue) {
      commit("SET_NOTIFICATIONS", newValue)
    },
    setBrands({ commit }, newValue) {
      commit("SET_BRANDS", newValue)
    },
    setCategories({ commit }, newValue) {
      commit("SET_CATEGORIES", newValue)
    },
    setParams({ commit }, newValue) {
      commit("SET_PARAMS", newValue)
    },
    setUserRole({ commit }, newValue) {
      commit("SET_USER_ROLE", newValue)
    },
    setAccessToken({ commit }, newValue) {
      commit("SET_ACCESS_TOKEN", newValue)
    },
    setRefreshToken({ commit }, newValue) {
      commit("SET_REFRESH_TOKEN", newValue)
    },
    setPagesTransition({ commit }, newValue) {
      commit("SET_TRANSITION", newValue)
    },
  },
  modules: {},
})
