<template>
  <div
    :class="{
      sidebar: true,
      ['--for-' + routeName]: true,
    }"
  >
    <div class="sidebar__logo">
      <router-link to="/">Editor</router-link>
    </div>

    <div v-if="isAuthenticated" class="sidebar__menu">
      <div
        v-for="(menuItem, num) in menuItemsPermitted"
        :key="num"
        :class="{
          sidebar__menu__item: true,
          '--active': menuItemInUrl(menuItem) || menuItem.active,
        }"
      >
        <router-link v-if="!menuItem.subItems.length" :to="'/' + menuItem.url">
          {{ menuItem.title }}
        </router-link>
        <div v-else>
          {{ menuItem.title }}
        </div>

        <div v-if="menuItem.subItems.length" class="sidebar__menu__subitems">
          <div
            v-for="(subItem, subItemIndex) in menuItem.subItems"
            :key="subItemIndex"
            :class="{ '--active': subMenuItemInUrl(subItem) }"
          >
            <router-link
              v-if="subItem.module_id"
              :to="'/' + menuItem.url + '/' + subItem.module_id"
              class="_item"
            >
              {{ subItem.title }}
            </router-link>
            <div v-else class="_item">
              {{ subItem.title }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isAuthenticated" class="sidebar__logout">
      <span @click="logout()" title="Выйти">
        <span class="_text">Выйти</span>
        <svg
          class="_icon"
          fill="none"
          height="24"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 16L21 12M21 12L17 8M21 12L7 12M13 16V17C13 18.6569 11.6569 20 10 20H6C4.34315 20 3 18.6569 3 17V7C3 5.34315 4.34315 4 6 4H10C11.6569 4 13 5.34315 13 7V8"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideBar",
  components: {},
  data() {
    return {
      fullView: true,
      menuItems: [
        { title: "Модули", url: "modules", active: false, subItems: [] },
        { title: "О нас", url: "about", active: false, subItems: [] },
      ],
    }
  },
  props: {},
  created() {},
  mounted() {
    this.menuItems[0].subItems = this.modules
  },
  computed: {
    isAuthenticated() {
      return this.$store.getters.getUserRole() != ""
    },
    params() {
      return this.$store.getters.getParams()
    },
    routeTitle() {
      return this.pageTitles[this.$route.name]
    },
    routeName() {
      return this.$route.name
    },
    pagesTransition() {
      return this.$store.getters.getPagesTransition()
    },
    modules() {
      return this.$store.getters.getMenuModules()
    },
    userPermissions() {
      return this.$store.getters.getUserRolePermissions()
    },
    menuItemsPermitted() {
      return this.menuItems.filter((item) => {
        return (
          this.userPermissions[item.url] != undefined &&
          this.userPermissions[item.url] != false
        )
      })
    },
  },
  methods: {
    menuItemInUrl(menuItem) {
      return this.$route.path.indexOf("/" + menuItem.url) !== -1
    },
    moduleId() {
      return this.$store.getters.getModuleById(this.$route.params.id).module_id
    },
    subMenuItemInUrl(menuItem) {
      return this.$route.params.id == menuItem.module_id
    },
    showSubItems(menuItem) {
      this.menuItems.forEach((item) => {
        item.active = false
      })
      menuItem.active = true
    },
    hideSubItems(menuItem) {
      if (this.routeName != menuItem.url) menuItem.active = false
    },
    handleScroll() {
      if (window.innerWidth <= 780) {
        if (window.scrollY > window.innerHeight) {
          this.scrolledDown = true
        } else {
          this.scrolledDown = false
        }
      }
    },
    isModulePermittted(moduleName) {
      if (this.userPermissions["modules"] == undefined) return false
      if (
        Array.isArray(this.userPermissions["modules"]) &&
        !this.userPermissions["modules"].includes(moduleName)
      )
        return false
      return true
    },
    permittedPacks(list, parent) {
      if (parent.url != "modules") return list
      else
        return list.filter((item) => {
          return (
            this.userPermissions["packs"] == undefined ||
            this.userPermissions["packs"].includes(item.pack_id)
          )
        })
    },
    permittedModules(list) {
      return list.filter((item) => {
        return this.isModulePermittted(item.module_name)
      })
    },
    logout() {
      this.$store.dispatch("setUserRole", "")
      this.$store.dispatch("setAccessToken", "")
      this.$store.dispatch("setRefreshToken", "")
      localStorage.setItem("userRole", "")
      localStorage.setItem("access_token", "")
      localStorage.setItem("refresh_token", "")
      this.$router.push("/login")
    },
  },
}
</script>
