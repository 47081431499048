<template>
  <div class="notifications">
    <TransitionGroup name="fade-group" :style="{ '--total': messages.length }">
      <div
        v-for="(msg, msgIndex) in messages"
        :key="msgIndex"
        :class="{ _msg: true, '--error': msg.type == 'error' }"
        :style="{ '--i': msgIndex }"
      >
        <div v-if="msg.title" class="_title" v-html="msg.title"></div>
        <div v-if="msg.text" class="_text" v-html="msg.text"></div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script>
export default {
  name: "NotificationsQueue",
  components: {},
  props: {},
  computed: {
    messages() {
      return this.$store.getters.getNotifications()
    },
  },
  watch: {
    messages(newVal) {
      if (newVal.length) {
        setTimeout(() => {
          this.$store.dispatch("setNotifications", [])
        }, 5000)
      }
    },
  },
}
</script>

<style lang="scss">
.notifications {
  position: fixed;
  top: 40px;
  right: 20px;
  z-index: 1001;
  ._msg {
    font-size: 1.6rem;
    color: #fff;
    background-color: #639fab;
    box-shadow: 0 0 18px #ccc;
    padding: 30px;
    border-radius: 12px;
    &.--error {
      background-color: #ff4b3e;
    }
  }
  ._msg + ._msg {
    margin-top: 20px;
  }
}
</style>
