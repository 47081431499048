<template>
  <div v-if="dataLoaded" :class="{ 'all-wrap': true }">
    <Sidebar />
    <Notifications />
    <div class="content">
      <router-view v-slot="{ Component, route }">
        <transition :name="route.meta.transition || 'pages-fade'">
          <component :is="Component" />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script>
import Sidebar from "./components/Sidebar.vue"
import Notifications from "@/components/Notifications.vue"
// import moment from "moment"
// import axios from "axios"

export default {
  name: "App",
  components: { Sidebar, Notifications },
  data() {
    return {
      dataLoaded: true,
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll)
    localStorage.setItem("startUrl", window.location.href)
    let loginTime = new Date(localStorage.getItem("last_login"))
    let currentTime = new Date()
    let loginHours = Math.abs(currentTime - loginTime) / 36e5
    if (loginHours > 0.5) {
      this.$store.dispatch("setUserRole", "")
      this.$store.dispatch("setAccessToken", "")
      this.$store.dispatch("setRefreshToken", "")
      localStorage.setItem("userRole", "")
      localStorage.setItem("access_token", "")
      localStorage.setItem("refresh_token", "")
      this.$router.push("/login")
    }

    if (localStorage.getItem("access_token")) {
      let role = localStorage.getItem("userRole")
      this.$store.dispatch("setUserRole", role)
      this.$store.dispatch(
        "setAccessToken",
        localStorage.getItem("access_token")
      )
    }
  },
  mounted() {},
  computed: {
    params() {
      return this.$store.getters.getParams()
    },
    dict() {
      return this.$store.getters.getDict()
    },
    modules() {
      return this.$store.getters.getModules()
    },
  },
  methods: {},
}
</script>
