import { createRouter, createWebHistory } from "vue-router"

// import store from "../store/index"

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Dashboard.vue"),
    meta: { transition: "pages-fade" },
  },
  {
    path: "/modules/:id",
    name: "module",
    meta: { transition: "pages-fade" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ModuleItems.vue"),
  },
  {
    path: "/modules",
    name: "module_list",
    meta: { transition: "pages-fade" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Modules.vue"),
  },
  {
    path: "/modules/:id/add",
    name: "module_add_item",
    meta: { transition: "pages-fade" },
    component: () => import("../views/ModuleNewItem.vue"),
    // children: [
    //   {
    //     path: "clone/:cloneid",
    //     name: "module_clone_item",
    //     component: import("../views/ModuleNewItem.vue"),
    //   },
    // ],
  },

  {
    path: "/modules/:id/edit/:itemid",
    name: "module_edit_item",
    meta: { transition: "pages-fade" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ModuleNewItem.vue"),
  },
  {
    path: "/about",
    name: "about",
    meta: { transition: "pages-fade" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: { transition: "pages-fade" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
]

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(function (to, from, next) {
  // if (to.meta.requiresAuth && !auth.isLoggedIn()) {
  if (to.name !== "login" && localStorage.getItem("userRole") == "") {
    next({ name: "login" })
    // return "/login"
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    // return {
    //   path: "/login",
    //   // save the location we were at to come back later
    //   query: { redirect: to.fullPath },
    // }
  } else next()
})

router.afterEach(() => {})

export default router
